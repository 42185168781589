@import "antd.css";

:root {
  --color-primary: #175ff7;
  --color-primary-background: #f5f8ff;
  --color-secondary: #5460F9;
  --color-secondary-background: #F4F5FE;
  --color-tertiary: #FC523B;
  --color-tertiary-background: #FFF5F3;
  --color-background: #f5f5f5;
  --color-text-dark: #202828;
  --color-text-light: #758ea6;
  --color-grey: #5e7380;
  --color-grey-border: #dde3e9;
  --color-grey-dark: #2B2B2B;
  --color-light-grey: #ececec;
  --color-border: #dde3e9;
  --color-border-light: #f9f9f9;
  --color-blue-grey: #9ea1bc;
  --color-dark: #1f1f1f;
  --color-error: #ff4d4f;
  --color-neutral-grey: #778c99;
  --color-light-blue: #ecf3ff;
  --color-green: #52c41a;

  --font-size-root: 16px;
  --font-size-small: 0.875rem;
  --font-size-xsmall: 0.75rem;
  --font-size-h1: 3.625rem;
  --font-size-h2: 2.75rem;
  --font-size-h3: 2rem;
  --font-size-h4: 1.625rem;
  --font-size-h5: 1.5rem;
  --font-size-h6: var(--font-size-root);

  --font-family-sans-serif: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
  --font-family-serif: "Noto Serif", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;

  --font-weight-bold: 600;

  --border-radius-button: 6px;

  --height-button: 40px;

  --border-grey: 1px solid var(--color-grey-border);
  --border-grey-dark: 1px solid var(--color-grey-dark);
  --border-primary: 1px solid var(--color-primary);

  --padding-button: 8px 12px;
}

/* Border-Box */

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* iPad Input Shadows */

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* iOS fix */
button:disabled,
input[type="submit"] {
  opacity: 1;
}

/* Force Simple Scrollbars */

body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height: 10px;
}

body ::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

body ::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.15);
}

body ::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 135, 139, 0.8);
}

/* Embedded content */

/* Add the correct display in IE 9-. */

audio,
video {
  display: inline-block;
}

/* Add the correct display in iOS 4-7. */

audio:not([controls]) {
  display: none;
  height: 0;
}

/* Remove the border on images inside links in IE 10-. */

img {
  border-style: none;
}

/* Hide the overflow in IE. */

svg:not(:root) {
  overflow: hidden;
}

/* Page */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: var(--font-size-root);
}

body {
  color: var(--color-text-dark);
  margin: 0;
  padding: 0px;
  overflow-x: hidden;
  min-width: 320px;
  background: #ffffff;
  font-size: var(--font-size-root);
  font-family: var(--font-family-sans-serif);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

@media (max-width: 999px) {
  body.no-scroll-mobile {
    overflow: hidden;
  }
}

/* Add the correct display in IE 9-. */

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

figcaption,
figure,
main {
  display: block;
}

/* Add the correct margin in IE 8. */

figure {
  margin: 1em 40px;
}

/**
  * 1. Add the correct box sizing in Firefox.
  * 2. Show the overflow in Edge and IE.
  */

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

/**
 * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

/* Prevent the duplicate application of `bolder` by the next rule in Safari 6. */

b,
strong {
  font-weight: inherit;
}

/* Add the correct font weight in Chrome, Edge, and Safari. */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Add the correct font style in Android 4.3-. */

dfn {
  font-style: italic;
}

/* Add the correct background and color in IE 9-. */

mark {
  background-color: #ff0;
  color: #000;
}

/* Add the correct font size in all browsers. */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

blockquote {
  margin-left: 16px;
}

button {
  cursor: pointer;
}

/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1em;
  padding: 0em;
  font-family: var(--font-family-serif);
}

h1,
h1:last-child {
  min-height: 1rem;
  font-size: var(--font-size-h1);
  margin-bottom: 16px;
}

h2 {
  font-size: var(--font-size-h2);
}

h3 {
  font-size: var(--font-size-h3);
}

h4 {
  font-size: var(--font-size-h4);
}

h5 {
  font-size: var(--font-size-h5);
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child {
  margin-top: 0em;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child {
  margin-bottom: 0em;
}

p {
  margin: 0em 0em 1em;
  line-height: 1.4285em;
}

p:first-child {
  margin-top: 0em;
}

p:last-child {
  margin-bottom: 0em;
}

.link-page {
  text-align: center;
}

.action-button {
  margin-right: 15px;
}

.flex-linebreak {
  flex-basis: 100%;
}

/*
  Esign template related css
*/
div.ant-select-item.optionV2 {
  color: #323232;
}

div.ant-select-item.optionV2 .ant-select-item-option-content {
  display: flex;
  justify-content: space-between;
}

div.ant-select-item.optionV2 .ant-select-item-option-content .profileName {
  font-size: 12px;
}
/* End esign template related css */

div.ant-select-item {
  background-color: #fff;
  font-weight: 500;
  font-size: 14px;
  color: #9d9fb5;
  padding: 10px 15px;
}
div.ant-select-item.ant-select-item-group {
  font-weight: 600;
}
div.ant-select-item.ant-select-item-group
  ~ div.ant-select-item:not(.ant-select-item-group) {
  padding-left: 30px;
  color: var(--color-text-dark);
}
div.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: #fff !important;
  background-color: var(--color-primary);
}
div.ant-select-dropdown {
  padding: 0;
  overflow: visible;
  border-radius: 0;
}
div.ant-select-dropdown > div {
  margin-top: -4px;
}
div.ant-select-single.ant-select-open .ant-select-selection-item {
  opacity: 1;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next,
.ant-carousel .slick-next:hover {
  font-size: 20px;
  color: #778f9b !important;
}

.ant-picker-dropdown .ant-picker-content th,
.ant-picker-dropdown .ant-picker-header-view button {
  font-weight: 600;
  font-size: 13px;
}
.ant-picker-dropdown .ant-picker-cell:not(.ant-picker-cell-in-view) {
  color: var(--color-border);
}
.ant-picker-dropdown .ant-picker-today-btn {
  font-weight: 600;
  font-size: 13px;
  color: var(--color-primary);
}

.ant-input:focus {
  border: var(--border-grey);
  box-shadow: 0 0 0 2px var(--color-secondary-background);
}

.ant-input:hover {
  border: var(--border-grey);
}

.ant-input-affix-wrapper {
  border-radius: var(--border-radius-button);
  border: var(--border-grey);
}

.ant-input-affix-wrapper:hover {
  border: var(--border-grey);
}

.ant-btn-primary:focus {
  background: transparent;
  border: var(--border-primary);
  color: var(--color-primary);
}

/* google autocomplete dropdown styling */
.pac-container {
  font-weight: 500;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 0;
}
.pac-item-query {
  color: var(--color-text-dark);
  border-color: var(--color-border);
}
.pac-item {
  color: var(--color-grey);
}
.pac-item:hover {
  background-color: var(--color-background);
}

/*.x-hellosign-embedded__modal-content {*/
/*  max-width: 70% !important;*/
/*}*/

/*.is-desktop .m-signer-mobile {*/
/*  max-width: none !important;*/
/*}*/
